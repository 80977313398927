/* Body Styles */
body {
  background-color: #121212;
  color: #e0e0e0;
  font-family: "Electrolize", sans-serif;
  margin: 0;
  padding: 0;
}

/* App Layout */
.App {
  text-align: center;
}

/* Navbar Styles */
.navbar {
  background-color: #1e1e1e;
  padding: 16px;
  text-align: center;
}

.nav-links {
  display: flex;
  justify-content: center;
}

.nav-link {
  color: #e0e0e0;
  text-decoration: none;
  margin: 0 20px;
  transition: color 0.3s, transform 0.3s;
}

.nav-link:hover {
  color: #a383d7;
  transform: scale(1.1);
}

/* Home Page Styles */
.home-container h1 {
  font-size: 2rem;
  color: #fff;
}

.home-nav {
  margin-top: 20px;
}

.nav-links-home {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
}

/* Project Styles */
.project-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
}

.project-item {
  background-color: #1e1e1e;
  border-radius: 8px;
  margin: 15px;
  padding: 10px;
  width: 220px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.project-item img {
  border-radius: 8px;
  width: 100%;
}

.project-image {
  width: 200px;
  height: 200px;
  border-radius: 8px;
  object-fit: cover;
}

/* Footer Styles */
footer {
  background-color: #1e1e1e;
  color: #e0e0e0;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.5);
  font-size: 0.9rem;
}

/* Footer Links and Sections */
.footer-link {
  color: #a383d7;
  text-decoration: none;
  margin: 5px 0;
}

.footer-link:hover {
  text-decoration: underline;
}

/* Optional: Spacing between sections */
.footer-section {
  margin: 10px 0;
}

.info-card {
  background-color: #1e1e1e;
  color: #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  margin: 20px auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.info-card h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.info-card p {
  line-height: 1.6;
}

.info-card h5 {
  margin-bottom: 8px;
  font-size: 1.2rem;
}

/* Flags Style */
.flags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.flag {
  font-size: 2rem;
  position: relative;
  transition: transform 0.3s;
}

.flag:hover {
  transform: scale(1.2);
}

.flag::after {
  content: attr(title);
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.85rem;
}

.flag:hover::after {
  opacity: 1;
}

/* Contact Links */
.contact-links {
  margin-top: 20px;
}

.contact-links a {
  margin-right: 10px;
}

/* Profile Image */
.profile-image {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Layout Styles */
.layout {
  text-align: center;
  margin: 20px;
}

.layout-title {
  font-size: 2rem;
  margin: 10px 0;
}

/* Heading Styles */
h2 {
  font-size: 1.5rem;
  color: #a383d7;
}

/* Skill Icons */
.skill-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 15px 0;
}

.skill-icons i {
  position: relative;
  font-size: 3rem;
  margin: 19px;
  color: #e0e0e0;
  transition: transform 0.3s;
}

.skill-icons i:hover {
  transform: scale(1.1);
}

/* Tooltip styling */
.skill-icons i::after {
  content: attr(title);
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s, bottom 0.3s;
  pointer-events: none;
  font-size: 0.85rem;
}

/* Show tooltip on hover */
.skill-icons i:hover::after {
  opacity: 1;
}

.skill-group-title {
  font-size: 1.5rem;
  margin: 20px 0;
  text-align: center;
}

/* General Link Styles */
a {
  color: #a383d7;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Nav Button Styles */
.nav-button {
  width: 120px;
  height: 45px;
  background-color: transparent;
  color: #a383d7;
  border: 2px solid #a383d7;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s;
}

.nav-button:hover {
  background-color: #a383d7;
  color: #121212;
}

/* Media Queries for Responsive Design */

/* Medium Devices (Tablets) */
@media (max-width: 768px) {
  .navbar {
    padding: 12px;
  }

  .nav-link {
    margin: 0 15px;
  }

  .project-item {
    width: 180px;
  }

  .footer-heading {
    font-size: 0.9rem;
  }

  .info-card {
    max-width: 100%;
  }
}

/* Small Devices (Mobile Phones) */
@media (max-width: 600px) {
  .info-card {
    max-width: 80%;
    margin: 10px auto;
    padding: 15px;
  }

  .info-card h3 {
    font-size: 1.2rem;
  }

  .info-card p {
    font-size: 0.9rem;
  }

  .info-card h5 {
    font-size: 1rem;
  }

  footer {
    padding: 15px;
  }

  .footer-heading,
  .footer-contact p,
  .footer-social p,
  .footer-text {
    font-size: 0.75rem;
  }

  .project-container {
    flex-direction: column;
    align-items: center;
  }

  .project-item {
    width: 100%;
    margin: 10px 0;
  }

  .nav-link {
    margin: 0 10px;
  }

  .layout-title {
    font-size: 1.5rem;
  }

  .skill-icons i {
    font-size: 2rem;
  }

  .contact-links {
    flex-direction: column;
    align-items: center;
  }

  .profile-image {
    width: 100px;
    height: 100px;
  }
}